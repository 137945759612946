

import {defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import PartnerRequestListItem from '@/components/PartnerRequestListItem.vue';
import {reviewRequest} from '@/core/constants';
import {
  getApprovedRequests,
  PartnerReviewRequestResource
} from '@/api/partnerReviewApi';
import RequestPartnerListFilter from '@/components/RequestPartnerListFilter.vue';

export default defineComponent( {
  name: 'RequestApprovedPage',
methods: { reviewRequest() {
return reviewRequest} } ,
  components: {RequestPartnerListFilter, PartnerRequestListItem, WhiteBoxMain, RequestPartnerNav},
  setup() {
    const approvedRequest = ref<PartnerReviewRequestResource[]>([])

    const filterBy = ref('7d');
    function setFilter(field: string) {
      filterBy.value = field;
      getApprovedRequestsAction(field)
    }

    onMounted(() => {
      getApprovedRequestsAction(filterBy.value)
    })

    const getApprovedRequestsAction = (filter:string) => {
      getApprovedRequests(filter).then(res => {
        approvedRequest.value = res;
      })
    }

    return {
      approvedRequest,
      setFilter,
      filterBy,
    }
  }

});
