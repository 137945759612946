

import {computed, defineComponent, onMounted, ref} from "vue";
import {
  ConfigKeys,
  CreateUpdateAppConfig,
  createUpdateAppConfig,
  getAppConfig, testMailConfigApi
} from '@/api/appConfigApi';
import {key} from '@/store';
import BaseButton from '@/components/BaseButton.vue';
import {notify} from '@kyvg/vue3-notification';
import RotateLoader from '@/components/RotateLoader.vue';
import ShowEye from '@/assets/img/showEye.vue';
import HideEye from '@/assets/img/hideEye.vue';

export default defineComponent( {
  name: 'AppConfigPage',
  components: {HideEye, ShowEye, RotateLoader, BaseButton},
  setup: () => {
    const isRequestingTestEmail = ref(false);
    const testEmail = ref('');
    const isEmailPasswordVisible = ref(false);
    const togglePasswordViewMode = () => {
      isEmailPasswordVisible.value = !isEmailPasswordVisible.value;
    }

    const activeTab = ref(1)

    const isEmailConfigurationCompleted = computed(() => {
      return formValues.value[ConfigKeys.mail_host] !== ''
        && formValues.value[ConfigKeys.mail_from_address] !== ''
        && formValues.value[ConfigKeys.mail_password] !== ''
        && formValues.value[ConfigKeys.mail_port] !== ''
        && formValues.value[ConfigKeys.mail_username] !== ''
        && formValues.value[ConfigKeys.mail_encryption] !== ''
    });

    const formValues = ref({
      [ConfigKeys.mail_host]: '',
      [ConfigKeys.mail_from_address]: '',
      [ConfigKeys.mail_password]: '',
      [ConfigKeys.mail_port]: '',
      [ConfigKeys.mail_username]: '',
      [ConfigKeys.mail_encryption]: '',
      [ConfigKeys.mail_from_name]: '',
      [ConfigKeys.mail_signature]: '',
      [ConfigKeys.mail_headline]: '',
      [ConfigKeys.mail_footer]: '',
    })


    onMounted(() => {
      getAppConfig()
        .then(res => {
          res.map(config => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formValues.value[config.key as any] = config.value;
          })
        });
    })


    const formatError = (errorMessage: string) => {
      return errorMessage
        .replace('0.value', 'mail host')
        .replace('3.value', 'mail port')
        .replace('1.value', 'mail from address')
        .replace('2.value', 'mail password')
        .replace('4.value', 'mail username')
        .replace('5.value', 'mail from name')
        .replace('5.key', 'mail from name')
        .replace('6.value', 'mail encryption')
        .replace('6.key', 'mail encryption')
        .replace('7.value', 'mail signature')
        .replace('7.key', 'mail signature')
        .replace('8.value', 'mail headline')
        .replace('8.key', 'mail headline')
        .replace('9.value', 'mail footer')
        .replace('9.key', 'mail footer')
    }

    const testEmailConfig = () => {
      if(isRequestingTestEmail.value === true) return;
      isRequestingTestEmail.value = true;
      testMailConfigApi(testEmail.value)
        .then(() => {
          notify({
            title: 'Mail sent successfully',
            type: 'success',
            duration: 8000,
            closeOnClick: true,
          });
        })
      .catch((error) => {
        let errorStr = error.response.data.message || 'Something wen wrong, please check your mail config' as string;
        notify({
          title: errorStr,
          type: 'error',
          duration: 8000,
          closeOnClick: true,
        });
      })
      .finally(() => { isRequestingTestEmail.value = false; })
    }


    const saveEmailConfiguration = () => {
      const payload: CreateUpdateAppConfig = [];
      [ConfigKeys.mail_host,
        ConfigKeys.mail_from_address,
        ConfigKeys.mail_password,
        ConfigKeys.mail_port,
        ConfigKeys.mail_username,
        ConfigKeys.mail_encryption].map((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.push({key, value: formValues.value[key]})
      })

      createUpdateAppConfig(payload)
        .then(() => {
          notify({
            title: 'Email configuration has been saved successfully',
            type: 'success',
            duration: 8000,
            closeOnClick: true,
          });
        })
        .catch(error => {
          let errorStr = error.response.data.message as string;
          const errorMessage = formatError(errorStr);
          notify({
            title: errorMessage,
            type: 'error',
            duration: 8000,
            closeOnClick: true,
          });
        })

    }

    const saveCustomizedEmailDesign = () => {
      const payload: CreateUpdateAppConfig = [];
      [ConfigKeys.mail_from_name,
        ConfigKeys.mail_signature,
        ConfigKeys.mail_headline,
        ConfigKeys.mail_footer].map((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload.push({key, value: formValues.value[key]})
      })

      createUpdateAppConfig(payload)
        .then(() => {
          notify({
            title: 'Customized email design has been saved successfully',
            type: 'success',
            duration: 8000,
            closeOnClick: true,
          });
        })
        .catch(error => {
          let errorStr = error.response.data.message as string;
          const errorMessage = formatError(errorStr);
          notify({
            title: errorMessage,
            type: 'error',
            duration: 8000,
            closeOnClick: true,
          });
        })
    }

    return {
      formValues,
      testEmailConfig,
      testEmail,
      isRequestingTestEmail,
      isEmailPasswordVisible,
      togglePasswordViewMode,
      activeTab,
      saveEmailConfiguration,
      saveCustomizedEmailDesign,
      isEmailConfigurationCompleted,
    }
  }
});
