
import {defineComponent} from "vue";

export default defineComponent({
  name: "TheModal",
  props: {
    isVisible: Boolean,
    minWidth: {
      type: Number,
      default: 520
    },
  },
  emits: ['onCancel', 'onConfirm'],
})
