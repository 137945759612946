

import {computed, defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import {useRoute} from 'vue-router';
import {
  getPartnerReviewCompleteRequest,
  getReviewQuiz,
  PartnerReviewQuestionResource,
  PartnerReviewQuizResource
} from '@/api/partnerReviewApi';
import {notify} from '@kyvg/vue3-notification';
import AdminReviewSentRequestQuestionAnswer from '@/components/AdminReviewSentRequestQuestion.vue';
import BaseButton from '@/components/BaseButton.vue';
import LoadingSvg from '@/assets/img/LoadingSvg.vue';
import TheModal from '@/components/TheModal.vue';
import useModal from '@/hooks/useModal';
import {formatInTimeZone} from 'date-fns-tz';
import router from '@/router';
import {requestApproved} from '@/core/constants';
export type QuestionResponseState = 'edited' | 'rejected' | 'accepted';
export default defineComponent( {
  name: 'PartnerReviewSingleQuiz',
  components: {
    TheModal, LoadingSvg, BaseButton, AdminReviewSentRequestQuestionAnswer, WhiteBoxMain, RequestPartnerNav
  },
  setup() {
    const route = useRoute();
    const {
      params: { id },
    } = route;

    const questions = ref<Array<PartnerReviewQuestionResource & {user_answer?: string | string[], is_answer_changed?: boolean }>>([]);
    const entireServerResponse = ref<PartnerReviewQuizResource | null>(null);
    const editingQuestionId = ref<number | null>(null);
    const confirmModal = useModal(false);

    const questionResponsesState = ref<Record<string, QuestionResponseState>>({})

    const setQuestionResponseState = (id: number, data: QuestionResponseState) => {
      questionResponsesState.value[id] = data;
    }

    const haveAnswers = computed(() => {
      console.log(questions.value.filter(q => q.is_answer_changed === true));
      return questions.value.filter(q => q.is_answer_changed === true).length > 0
    })

    onMounted(() => {
      getReviewQuiz(id as string)
      .then(response => {
        questions.value = response.partner_review_questions;
        entireServerResponse.value = response;
      })
    })

    const isQuestionActive = (questionId: number) =>
      editingQuestionId.value !== null && questionId === editingQuestionId.value;

    const handleValueUpdate = (questionId: number, val: string) => {
      const questionIX = getQuestionIndexById(questionId);
      questions.value[questionIX].user_answer = val;
    };

    const getQuestionIndexById = (questionId: number): number => {
      let questionIndex = -1;
      questions.value.forEach((question, ix) => {
        if (question.id === questionId) {
          questionIndex = ix;
        }
      });
      return questionIndex;
    };

    const completeReview = () => {
      const payload: Record<number, {
        id?: number,
        value?: string | string[]
      }> = {};
      const keys = Object.keys(questionResponsesState.value);
      questions.value.forEach((question) => {
        if(keys.indexOf(question.id.toString()) !== -1) {
          console.log('asdasdas')
          let data: {
            id?: number,
            value?: string | string[]
          } = {};
          if(questionResponsesState.value[question.id] === 'edited') {
            data.value = question.user_answer
          } else if(questionResponsesState.value[question.id] === 'rejected') {
            data.id = question.original_answer.id
          }  else if(questionResponsesState.value[question.id] === 'accepted') {
            data.id = question.partner_answer.id
          }
          payload[question.id] = data
        }
      });
      if(!entireServerResponse.value) throw new Error('No Data')
      getPartnerReviewCompleteRequest({
        answer: payload,
        request_uuid: entireServerResponse.value.partner_review_request.uuid,
      })
      .then(() => {
        notify({
          title: "Review completed successfully.",
          type: 'success',
          duration: 5000,
          closeOnClick: true,
        });
        router.push({
          name: requestApproved,
        });
      })
      .catch(error => {
        confirmModal.close();
        notify({
          title: error.response.data.message,
          type: 'error',
          duration: 5000,
          closeOnClick: true,
        });
      })
    };

    const formatDate = (date: string) => {
      // eslint-disable-next-line max-len
      return formatInTimeZone(new Date(date), Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM d, yyyy HH:MM');
    };


    return {
      questions,
      isQuestionActive,
      handleValueUpdate,
      completeReview,
      confirmModal,
      entireServerResponse,
      formatDate,
      questionResponsesState,
      setQuestionResponseState,
      haveAnswers,
    }
  },

});
