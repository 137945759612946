import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7a1e75a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "unread-counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hamburger = _resolveComponent("hamburger")!
  const _component_NewDocumentSvg = _resolveComponent("NewDocumentSvg")!
  const _component_menu_link = _resolveComponent("menu-link")!
  const _component_ArchiveSvg = _resolveComponent("ArchiveSvg")!
  const _component_ReportSvg = _resolveComponent("ReportSvg")!
  const _component_UploadSvg = _resolveComponent("UploadSvg")!
  const _component_RequestPartnerSvg = _resolveComponent("RequestPartnerSvg")!
  const _component_SettingSvg = _resolveComponent("SettingSvg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { opened: _ctx.isOpened }])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: "close-sidebar-menu",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onToggleSidebar'))),
        onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('onToggleSidebar')), ["enter"]))
      }, [
        _createVNode(_component_hamburger)
      ], 32)
    ]),
    (_ctx.isOpened)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resetSearchValue && _ctx.resetSearchValue(...args)))
          }, [
            _createVNode(_component_menu_link, {
              text: "EPX Engagements",
              url: "/"
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_NewDocumentSvg)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_menu_link, {
            text: "Archives",
            url: "/archive"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_ArchiveSvg)
            ]),
            _: 1
          }),
          _createVNode(_component_menu_link, {
            text: "Report Dashboard",
            url: "/report"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_ReportSvg)
            ]),
            _: 1
          }),
          _createVNode(_component_menu_link, {
            text: "Bulk Behaviors",
            url: "/upload"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_UploadSvg)
            ]),
            _: 1
          }),
          _createVNode(_component_menu_link, {
            text: "Request to CBP/Partner",
            url: "/request-partner"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_RequestPartnerSvg)
            ]),
            suffix: _withCtx(() => [
              (_ctx.unreadRequestToPartnerCount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.unreadRequestToPartnerCount), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_menu_link, {
            text: "Settings",
            url: "/app-config"
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_SettingSvg)
            ]),
            _: 1
          }),
          (_ctx.isLogInEnabled)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "log-out-button",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
              }, "Log Out"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}