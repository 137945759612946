import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f34a5800"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_2 = { class: "content-box" }
const _hoisted_3 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "modal-box",
          style: _normalizeStyle(_ctx.minWidth ? { minWidth: `${_ctx.minWidth}px` } : {})
        }, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ])
        ], 4)
      ]))
    : _createCommentVNode("", true)
}