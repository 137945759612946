

import {computed, defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import {getSentRequest, PartnerReviewRequestResource} from '@/api/partnerReviewApi';
import PartnerRequestListItem from '@/components/PartnerRequestListItem.vue';
import RequestPartnerListFilter from '@/components/RequestPartnerListFilter.vue';
export default defineComponent( {
  name: 'RequestSentPage',
  components: { PartnerRequestListItem, WhiteBoxMain, RequestPartnerNav, RequestPartnerListFilter},
  setup() {
    const sentRequest = ref<PartnerReviewRequestResource[]>([])
    const wasRequestComplete = ref(false);

    const filterBy = ref('7d');
    function setFilter(field: string) {
      filterBy.value = field;
      getSentRequestAction(field)
    }

    const noDataFromResponse = computed(() => {
      return wasRequestComplete.value === true && sentRequest.value.length === 0;
    })
    onMounted(() => {
      getSentRequestAction(filterBy.value)
    })

    const getSentRequestAction = (filter: string) => {
      getSentRequest(filter).then(res => {
        sentRequest.value = res;
        wasRequestComplete.value = true;
      })
    }

    return {
      sentRequest,
      noDataFromResponse,
      setFilter,
      filterBy,
    }
  },

});
