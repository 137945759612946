<template>
  <DropDown class="mb-32 ml-20">
    <template #label>
      <div class="d-flex align-items-center">
        <div class="sort-label">Filter by:</div>
        <div class="ml-2">{{ labelAlias[value] }}</div>
      </div>
    </template>
    <template #list>
      <div @click="$emit('handleValueChange','7d')">Last week</div>
      <div @click="$emit('handleValueChange','1m')">Last month</div>
      <div @click="$emit('handleValueChange','3m')">Last 3 months</div>
      <div @click="$emit('handleValueChange','6m')">Last 6 months</div>
      <div @click="$emit('handleValueChange','1y')">Last year</div>
      <div @click="$emit('handleValueChange','2y')">Last 2 year</div>
    </template>
  </DropDown>
</template>
<script>
import { defineComponent } from 'vue';
import DropDown from "@/components/DropDown.vue";

export default defineComponent({
  name: 'RequestPartnerListFilter',
  components: {DropDown},
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  emits: ['handleValueChange'],
  setup() {

    const labelAlias = {
      '7d': 'Last week',
      '1m': 'Last month',
      '3m': 'Last 3 months',
      '6m': 'Last 6 months',
      '1y': 'Last year',
      '2y': 'Last 2 year',
    }

    return {
      labelAlias
    }
  },
});
</script>
<style lang="sass">
@import '../assets/sass/base/colors'
//.radio-button
//  padding: 16px 34px
//  font-family: Inter, sans-serif;
//  font-size: 14px;
//  color: $purple;
//  background: $grey-5
//  border: 1px solid $grey-4
//  border-radius: 8px
//  transition: .3s
//  &.radio-button-active, &:hover
//    background-color: $white
//    border: 1px solid $purple
//    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
</style>
