
import { defineComponent, ref } from 'vue';
import TheHeader from '@/components/TheHeader.vue';
import TheSidebar from '@/components/TheSidebar.vue';

export default defineComponent({
  name: 'CienaLayout',
  components: {
    TheHeader,
    TheSidebar,
  },
  props: {
    noSidebar: {
      type: Boolean,
      default: false,
    },
    noSearch: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['onSearchBarSubmit'],
  setup() {
    const browserWidth = window.innerWidth ||  document.documentElement.clientWidth ||  document.body.clientWidth;
    const isSidebarOpened = ref(browserWidth > 950);

    const openSidebar = () => {
      isSidebarOpened.value = true;
    };
    const closeSidebar = () => {
      isSidebarOpened.value = false;
    };

    const onToggleSidebar = () => {
      isSidebarOpened.value = !isSidebarOpened.value;
    }

    return {
      isSidebarOpened,
      openSidebar,
      closeSidebar,
      onToggleSidebar,
    };
  },
});
